<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  ><!-- style="z-index: 9999!important" -->
    <!-- main menu header-->
    <div
      class="navbar-header expanded mb-2"
    >
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li :class="{ 'nav-item': true, 'mr-auto': true, 'mx-auto': !isVerticalMenuCollapsed || (isMouseHovered) }">
            <b-link
              class="navbar-brand myMargin"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  v-if="!isVerticalMenuCollapsed || (isMouseHovered)"
                  :src="appLogoComplete"
                  alt="logo"
                  class="logoComplete"
                />
<!--                 :class="{ logoComplete : !isVerticalMenuCollapsed || (isMouseHovered), logoMinified : isVerticalMenuCollapsed === true }"
 -->                <b-img
                  v-else
                  :src="appLogoMinified"
                  alt="logo"
                  class="logoMinified"
                />
<!--                   :class="{ logoComplete : isVerticalMenuCollapsed === false, logoMinified : isVerticalMenuCollapsed === true }"
 -->              </span>
              <!-- <h2 class="brand-text">
                {{ appName }}
              </h2> -->
            </b-link>
          </li>
          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom mt-1"
    />
    <b-row>
      <b-col
        cols="11"
        class="mx-auto mt-2"
      >
        <ChangeConnectionSelect
          v-if="!isVerticalMenuCollapsed || (isMouseHovered)"
          :connection-options="connections"
        />
        <div
          v-else
          class="d-flex justify-content-center"
        >
          <feather-icon
            size="20"
            icon="RefreshCwIcon"
          />
        </div>
      </b-col>
    </b-row>
    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="menuList"
        class="navigation navigation-main"
        @from-vertical-nav-menu-items="emitToLayoutVertical"
      />
      <!-- :from-vertical-nav-menu-items="emitToLayoutVertical" -->
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
// import navMenuItems from '@/navigation/vertical'
// import axios from 'axios'
// import { myMenu, getSideBarMenu } from '@/navigation/vertical'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BLink,
  BImg,
  BRow,
  BCol,
} from 'bootstrap-vue'
import {
  provide,
  computed,
  ref,
  reactive,
} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import ChangeConnectionSelect from '@core/components/select-connection-user/ChangeConnectionSelect.vue'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BRow,
    BCol,
    ChangeConnectionSelect,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
    menuList: {
      type: Array,
      required: true,
    },
    connections: {
      type: Array,
      required: true,
    },
  },
  emits: ['from-vertical-nav-menu'],
  setup(props, { emit }) {
    const state = reactive({
      error: '',
      double: computed(() => state.count * 2),
    })
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)
    const { skin } = useAppConfig()
    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      suppressScrollY: false,
    }
    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))
    // App Name
    const {
      appName, appLogoImage, appLogoComplete, appLogoMinified,
    } = $themeConfig.app

    const emitToLayoutVertical = newMenu => {
      // console.log('newMenuemitToLayout', newMenu)
      emit('from-vertical-nav-menu', newMenu)
    }
    return {
      state,
      appLogoComplete,
      appLogoMinified,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,

      // my function
      emitToLayoutVertical,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.myMargin{
  margin-top: 1rem !important;
}
.logoMinified {
  min-width: 50px !important;
  min-height: 50px !important;
}

.logoComplete {
  min-width: 102px !important;
  min-height: 50px !important;
}
</style>
