<template>
  <div
    v-if="item.route !== 'button'"
  >
    <li
      v-if="item.isActive && (canViewVerticalNavMenuLink(item))"
      class="nav-item"
      :class="{
        'active': isActive,
        'disabled': item.disabled
      }"
    >
      <b-link
        v-bind="linkProps"
        class="d-flex align-items-center"
      >
        <!--
          <feather-icon
          :icon="item.icon || 'AlertOctagonIcon'"
          size="9"
          :src="require('../../../../../../assets/icon-menu/'+item.icon+'.svg')"
        />
        -->
        <!-- <img
          :src="require('@/@core/assets/iconpro/'+ item.icon +'.svg')"
          width="25"
          height="25"
          class="mr-1"
        > -->
        <img
          :src="isDark ? require('@/@core/assets/icone-light-dark/menu/'+ item.icon + '-dark' + '.svg') : require('@/@core/assets/icone-light-dark/menu/'+ item.icon + '.svg')"
          width="25"
          height="25"
          class="mr-1"
        >
        <span class="menu-title text-truncate">{{ t(item.title) }}</span>
        <b-badge
          v-if="item.tag"
          pill
          :variant="item.tagVariant || 'primary'"
          class="mr-1 ml-auto"
        >
          {{ item.tag }}
        </b-badge>
      </b-link>
    </li>
  </div>
  <div v-else>

    <li
      class="nav-item"
      :class="{
        'active': isActive,
        'disabled': item.disabled
      }"
    >
      <b-button
        v-b-modal="'modal-personalize-menu-sidebar'"
        variant="flat-secondary"
      >
        <!-- <feather-icon
          :icon="item.icon || 'AlertOctagonIcon'"
          size="18"
        /> -->
        <!-- <img
          :src="require('@/@core/assets/iconpro/'+ item.icon +'.svg')"
          width="18"
          height="18"
          class="mr-1"
        > -->
        <img
          :src="isDark ? require('@/@core/assets/icone-light-dark/menu/'+ item.icon + '-dark' + '.svg') : require('@/@core/assets/icone-light-dark/menu/'+ item.icon + '.svg')"
          width="18"
          height="18"
          class="mr-1"
        >
        <span
          class="menu-title text-truncate navigation"
          style="background-color:transparent"
        >{{ t(item.title) }}</span>
        <b-badge
          v-if="item.tag"
          pill
          :variant="item.tagVariant || 'primary'"
          class="mr-1 ml-auto"
        >
          {{ item.tag }}
        </b-badge>
      </b-button>
      <PersonalizeMenuSidebar2  />
      <!--  @new-menu="toVerticalNavMenuGroup"-->
    </li>
  </div>
</template>

<script>
import {
  onBeforeMount, computed,
} from '@vue/composition-api'
import {
  BLink, BBadge, VBModal, BButton,
} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { useUtils as useAclUtils } from '@core/libs/acl'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import PersonalizeMenuSidebar2 from '@/views/pages/settings/PersonalizeMenuSidebar2.vue'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
    PersonalizeMenuSidebar2,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  // emits: ['from-vertical-nav-menu-link'],
  setup(props) {
    onBeforeMount(() => {
    })
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    /* const toVerticalNavMenuGroup = newMenu => {
      // console.log('newMenu in EmitNewMEnu', newMenu)
      emit('from-vertical-nav-menu-link', newMenu)
    } */
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    return {
      isActive,
      linkProps,
      updateIsActive,
      // dark
      skin,
      isDark,
      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,

      // functionMy
      // toVerticalNavMenuGroup,
    }
  },

}
</script>

<style scoped>
/*   .menu-title {
    font-size: 0.8rem;
  } */
</style>
