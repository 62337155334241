<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <b-row no-gutters>
          <div
            style="width: 40px"
            class="mr-2"
            @click="goBack()"
          >
            <b-button
              v-if="$route.name !== 'invoice-preview'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
            >
              <img
                :src="require('@/@core/assets/iconpro/'+ 'arrow-left' +'.svg')"
                width="18"
                height="18"
                class="mr-1 mx-auto"
              >
            </b-button>
          </div>
          <b-col>
            <app-breadcrumb />
          </b-col>
        </b-row>
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BRow, BCol, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    AppBreadcrumb,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
}
</script>

<style>

</style>
