/* eslint-disable  */
import axios from 'axios'

export function jobBattute() {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, {},
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Dev-Battuta',
          metodo: 'jobBattute',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}

export function getBattute(rangeDate) {
  // console.log('dentro reparti', puntiVendita)
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, rangeDate,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Dev-Battuta',
          metodo: 'getScontrini',
          ACL: '',
        },
      })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}

  