<template>
  <layout-vertical
    :menu="data"
    @emit-route-bookmarks-from-layout-to-layout="emitToNavbar"
  >

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
        :bookmarkss-to-search="bookToSearch"
      /><!--bookmarksRouteToEmit :bookmarkss="bookmarksRouteToEmit"-->
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'
import { jobBattute } from '@/@core/api-service/devices/jobBattute'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
  },
  data() {
    return {
      // data: this.$store.getters['menuLogin/getMenu'],
      // showCustomizer: $themeConfig.layout.customizer,
      // bookmarksRouteToEmit: JSON.parse(sessionStorage.getItem('route')),
    }
  },
  computed: {
    data() {
      /* const prova = this.$store.getters['menuLogin/getMenu']
      const prova2 = prova.map(({ children }) => console.log('child', children))
      console.log('prova map', prova)
      console.log('prova2 map', prova2) */
      // console.log(this.$store.getters['menuLogin/getMenu'])
      return this.$store.getters['menuLogin/getMenu']
    },
    book() {
      return this.$store.getters['menuLogin/getBookmarks']
    },
    bookToSearch() {
      return this.$store.getters['menuLogin/getBookmarksToSearch']
    },
  },
  mounted() {
    // console.log('prima veniva caricato qui il menu')
    this.$store.dispatch('menuLogin/getMenuUtente')
    jobBattute().then(res => res).catch(e => e)
    let time = 1
    const interval = setInterval(() => {
      if (time <= 3) {
        jobBattute().then(res => res).catch(e => e)
        time += 1
      } else {
        clearInterval(interval)
      }
    }, 300000)
    // jobBattute().then(res => console.log(res)).catch(e => e)
  },
  methods: {
    emitToNavbar(route) {
      // console.log('route', route)
      this.bookmarksRouteToEmit = [...route]
    },
    loadRoute() {
      return this.bookmarksRouteToEmit
    },
  },
}
</script>
