<template>
  <b-navbar-nav class="nav">
    <b-nav-item
      v-for="(bookmark, index) in bookmarks"
      :id="`bookmark-${index}`"
      :key="index"
      :to="bookmark.route"
    >
      <!--<feather-icon
        :icon="bookmark.icon"
        size="21"
      />-->
      <!-- <img
        :src="require('@/@core/assets/iconpro/'+ bookmark.icon +'.svg')"
        width="18"
        height="18"
      > -->
      <img
        :src="isDark ? require('@/@core/assets/icone-light-dark/menu/'+ bookmark.icon + '-dark' + '.svg') : require('@/@core/assets/icone-light-dark/menu/'+ bookmark.icon + '.svg')"
        width="18"
        height="18"
        class="mr-1"
      >
      <!--<img
        :src="require('@/@core/assets/icon-menu/'+'500px'+'.svg')"
        width="18"
        height="18"
      >-->
      <!-- icona bookmarks navbar -->
      <b-tooltip
        triggers="hover"
        :target="`bookmark-${index}`"
        :title="bookmark.title"
        :delay="{ show: 1000, hide: 50 }"
      />
    </b-nav-item>
    <b-nav-item-dropdown
      link-classes="bookmark-star"
      lazy
      @hidden="resetsearchQuery"
    >
      <feather-icon
        slot="button-content"
        icon="StarIcon"
        size="21"
        class="text-warning"
      />
      <!-- Dropdown Content -->
      <li style="min-width:300px">
        <div class="p-1">
          <b-form-input
            id="boomark-search-input"
            v-model="searchQuery"
            placeholder="Explore Vuexy..."
            autofocus
          />
        </div>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="search-list search-list-bookmark scroll-area"
          :class="{'show': filteredData.pages && filteredData.pages.length }"
          tagname="ul"
        >

          <b-dropdown-item
            v-for="(suggestion, index) in filteredData.pages || bookmarks"
            :key="index"
            class="suggestion-group-suggestion cursor-pointer"
            link-class="d-flex align-items-center"
            :to="suggestion.route"
            @mouseenter="currentSelected = index"
          >
            <feather-icon
              :icon="suggestion.icon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">{{ suggestion.title }}</span>
            <feather-icon
              icon="StarIcon"
              class="ml-auto text-warning"
              size="16"
              :class="{'fill-star': suggestion.isBookmarked }"
              @click.stop.prevent="toggleBookmarked(suggestion)"
            />

          </b-dropdown-item>
          <b-dropdown-item
            v-show="!(filteredData.pages && filteredData.pages.length) && searchQuery"
            disabled
          >
            No Results Found.
          </b-dropdown-item></vue-perfect-scrollbar>
      </li>
    </b-nav-item-dropdown>

  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav, BNavItem, BTooltip, BNavItemDropdown, BFormInput, BDropdownItem,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAppConfig from '@core/app-config/useAppConfig'
import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
import {
  ref, watch, reactive, computed,
} from '@vue/composition-api'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import router from '@/router'
import store from '@/store'
// import searchAndBookmarkData from '../search-and-bookmark-data'

export default {
  components: {
    BNavbarNav, BNavItem, BTooltip, BNavItemDropdown, BFormInput, VuePerfectScrollbar, BDropdownItem,
  },
  props: {
    /* bookmarksss: {
      type: Array,
      default: (() => []),
    }, */
  },
  computed: {
    /* mybookmarks() {
      return this.bookmarksss
    }, */
  },
  /* methods: {
    alertOk() {
      this.$toast({
        component: ToastificationContent,
        props: {
          text: 'Preferenze Salvate Correttamente!',
          icon: 'StarIcon',
          variant: 'success',
        },
      })
    },
  }, */
  setup(props) {
    // console.log('bookmarksss', props.bookmarksss)
    const state = reactive({
      // bookmark1: props.bookmarksss,
      // bookmark1: computed(() => ref(props.bookmarksss)),
      bookmark: computed(() => ref([...store.getters['menuLogin/getBookmarks']])),
      bookmarksToSearch: computed(() => ref([...store.getters['menuLogin/getBookmarksToSearch']])),
    })
    // console.log('sono dentro stup', store.getters['menuLogin/getBookmarks'])
    // const bookmarks = ref(JSON.parse(sessionStorage.getItem('bookmarks')))
    // const bookmarks = ref(props.bookmarksss)
    const bookmarks = ref([])
    const bookmarksToSearch = ref([])

    watch(() => store.getters['menuLogin/getBookmarks'], async value => {
      // console.log('value1', value)
      const newMenu = [...value]
      const jsonToSend = []
      newMenu.forEach(element => {
        // console.log(element)
        jsonToSend.push({
          key: element.key,
          isActive: element.isActive ? '1' : '1',
          isBookmarked: '1',
        })
      })
      const stringArray = JSON.stringify(jsonToSend)
      await axios.post(process.env.VUE_APP_URL_API_SERVER,
        stringArray,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: String(JSON.parse(sessionStorage.getItem('activeAgency')).value),
            classe: 'Utente',
            metodo: 'setMenu',
            ACL: 'button',
          },
        }).then(res => res)
        .catch(e => e)
      bookmarks.value = [...value]
      // console.log('watch', JSON.stringify(jsonToSend))
    })
    watch(() => store.getters['menuLogin/getBookmarksToSearch'], value => {
      // console.log('value2', value)
      // bookmarksToSearch.value.splice(0)
      bookmarksToSearch.value = [...value]
      // console.log('getBookmarksToSearch watch', bookmarksToSearch.value)
    })
    // console.log('sessionStorage bookmarks1', bookmarks)
    // const routes = ref([...store.getters['menuLogin/getBookmarks']])
    const routes = ref(props.mybookmarks)
    // ref(JSON.parse(sessionStorage.getItem('route')))
    // const bookmarksToSearch = ref(state.bookmarksToSearch)
    // console.log('bookmarks', bookmarks.value)
    // console.log('bookmarksToSearch', bookmarksToSearch.value)
    /* const prepareBookmarksToSearch = () => {
      routes.value.forEach(route => {
        route.children.forEach(r => {
          if (!Object.prototype.hasOwnProperty.call(r, 'children')) {
            bookmarksToSearch.value.push(
              {
                title: r.title,
                route: { name: r.route },
                icon: r.icon,
                isBookmarked: r.isBookmarked,
              },
            )
          }
          if (Object.prototype.hasOwnProperty.call(r, 'children')) {
            r.children.forEach(rchildrennested => bookmarksToSearch.value.push(
              {
                icon: rchildrennested.icon,
                route: { name: rchildrennested.route },
                title: rchildrennested.title,
                isBookmarked: rchildrennested.isBookmarked,
              },
            ))
          }
        })
      })
      return bookmarksToSearch
    }
    prepareBookmarksToSearch() */
    /* routes.value.forEach(route => {
      route.children.forEach(r => {
        if (!Object.prototype.hasOwnProperty.call(r, 'children')) {
          bookmarksToSearch.value.push(
            {
              title: r.title,
              route: { name: r.route },
              icon: 'ArrowDownRightIcon',
              isBookmarked: r.isBookmarked,
            },
          )
        }
        if (Object.prototype.hasOwnProperty.call(r, 'children')) {
          r.children.forEach(rchildrennested => bookmarksToSearch.value.push(
            {
              icon: 'ArrowDownLeftIcon',
              route: { name: rchildrennested.route },
              title: rchildrennested.title,
              isBookmarked: rchildrennested.isBookmarked,
            },
          ))
        }
      })
    }) */
    // console.log('getBookmarks', store.getters['menuLogin/getBookmarks'])
    // console.log('getBookmarksToSearch', store.getters['menuLogin/getBookmarksToSearch'])
    // console.log('f1', bookmarks.value)
    // bookmarks.value = bookmarksToSearch.value.filter(page => page.isBookmarked)
    // console.log('f2', bookmarks.value)
    // const searchAndBookmarkDataPages = ref(searchAndBookmarkData.pages)
    // bookmarks original
    /* const bookmarks = ref(searchAndBookmarkData.pages.data.filter(page => page.isBookmarked))
    */
    const currentSelected = ref(-1)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const {
      searchQuery,
      resetsearchQuery,
      filteredData,
    // } = useAutoSuggest({ data: { pages: searchAndBookmarkDataPages }, searchLimit: 6 })
    } = useAutoSuggest({ data: { pages: { key: 'title', data: bookmarksToSearch.value } }, searchLimit: 6 })

    watch(searchQuery, val => {
      // console.log('search query val', val)
      store.commit('app/TOGGLE_OVERLAY', Boolean(val))
    })
    watch(filteredData, val => {
      // console.log('filteredData val', val)
      currentSelected.value = val.pages && !val.pages.length ? -1 : 0
    })

    // controlla l'emit da togliere viene chiamato questo watch al save del personalizza menu
    watch(() => props.bookmarksss, (nextVal, prevVal) => {
      // console.log('wathc')
      if (prevVal && nextVal) {
        bookmarksToSearch.value.length = 0
        routes.value = [...nextVal]
        // prepareBookmarksToSearch()
        bookmarks.value = bookmarksToSearch.value.filter(page => page.isBookmarked)
      }
    })
    const suggestionSelected = () => {
      const suggestion = filteredData.value.pages[currentSelected.value]
      router.push(suggestion.route).catch(() => {})
      resetsearchQuery()
    }

    const toggleBookmarked = item => {
      // Find Index of item/page in bookmarks' array
      // console.log('toogle', item)
      const pageIndexInBookmarks = bookmarks.value.findIndex(i => i.route === item.route)
      // If index is > -1 => Item is bookmarked => Remove item from bookmarks array using index
      // Else => Item is not bookmarked => Add item to bookmarks' array
      if (pageIndexInBookmarks > -1) {
        bookmarks.value[pageIndexInBookmarks].isBookmarked = false
        store.commit('menuLogin/pushBookmarks', item)
        bookmarks.value.splice(pageIndexInBookmarks, 1)
      } else {
        store.commit('menuLogin/pushBookmarks', item)
        bookmarks.value.push(item)
        // console.log('dopo il push', bookmarks.value)
        bookmarks.value[bookmarks.value.length - 1].isBookmarked = true
      }
    }
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    return {
      state,
      bookmarks,
      perfectScrollbarSettings,
      currentSelected,
      suggestionSelected,
      toggleBookmarked,
      // dark
      skin,
      isDark,
      // searchAndBookmarkDataPages,
      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,

      // prepareBookmarksToSearch,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
    position: fixed;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.7s;
    transition: all 0.7s;
    z-index: -1;

    &:not(.show) {
      pointer-events: none;
    }

    &.show {
      cursor: pointer;
      z-index: 10;
      opacity: 1;
    }
}
</style>
<style scoped>
.fill-star {
  fill: #ff9f43;
}
</style>
