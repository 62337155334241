<template>
  <b-row
    v-if="connectionOptions.length > 1"
    class="mt-2"
  >
    <b-col
      cols="10"
      class="pr-0"
    >
      <v-select
        v-model="selectedConnection"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :clearable="false"
        :options="connectionOptions"
        class="select-size-sm radius"
      />
    </b-col>
    <b-col
      cols="2"
      class="pl-0"
    >
      <b-button
        variant="outline-primary"
        class="btn-icon radiusleft"
        size="sm"
      >
        <feather-icon icon="RefreshCwIcon" />
      </b-button>
    </b-col>
  </b-row>
  <b-row
    v-else
  >
    <p class="m-auto">Collegato con:<span class="d-block"><strong>{{ selectedConnectionComputed }}</strong></span>
    </p>
    <!-- <p class="m-auto">Collegato con:<span class="d-block"><strong>{{ selectedConnection.title || '' }}</strong></span>
    </p> -->
  </b-row>

</template>

<script>
import vSelect from 'vue-select'
import { BRow, BCol, BButton } from 'bootstrap-vue'

export default {
  components: {
    vSelect,
    BButton,
    BRow,
    BCol,
  },
  props: {
    connectionOptions: { type: Array, required: true },
  },
  data() {
    return {
      selectedConnection: JSON.parse(sessionStorage.getItem('activeAgency')),
      disabledSelect: true,
      // connectionOptions2: [{ title: 'Agenzia1', value: 'a1' }, { title: 'Agenzia2', value: 'a2' }, { title: 'Agenzia3', value: 'a3' }],
    }
  },
  computed: {
    selectedConnectionComputed() {
      // console.log('cambio connessione', this.selectedConnection)
      if (this.selectedConnection) {
        return this.selectedConnection.title
      }
      return ''
    },
  },
  watch: {
  },
  beforeMount() {
  },
  created() {
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.radius div {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.radius input:enabled {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.v-select div#vs1__combobox{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.radiusleft {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>
