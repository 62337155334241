<template>
  <b-modal
    id="modal-personalize-menu-sidebar"
    ref="modal-personalize-sidebar-menu"
    ok-only
    ok-title="Conferma"
    :body-class="['p-0']"
    centered
    title="Personalizza Menu"
    @ok="handleSaveMenu"
  >
    <div
      class="accordion"
      role="tablist"
    >
      <b-card
        v-for="(routeGroup, index) in allRoutes"
        :key="`${routeGroup.route}-${index}`"
        no-body
        class="mb-0"
        :body-class="['p-0']"
      >
        <b-card-header
          header-tag="header"
          class="p-1"
          role="tab"
        >
          <h6
            class="mb-0"
            variant="info"
            style="font-size: 1rem"
          >{{ routeGroup.title }}</h6>
          <div class="d-flex">
            <b-badge
              pill
              class="mr-1"
              style="width: 120px; background-color: transparent!important"
            >
              <feather-icon
                icon="StarIcon"
                class="mr-25 fill-star text-warning"
              />
              <span class="mr-1 text-dark">{{ countBookmarks(routeGroup, index) }}</span>
              <feather-icon
                icon="EyeIcon"
                class="mr-25 text-dark"
              />
              <span class="mr-1 text-dark">{{ countVisible(routeGroup) }}</span>
              <feather-icon
                icon="EyeOffIcon"
                class="mr-25 text-dark"
              />
              <span class="text-dark">{{ countNotVisible(routeGroup) }}</span>
            </b-badge>

            <!-- <feather-icon
              icon="EyeIcon"
              size="20"
            /> {{ countNotVisible(routeGroup) }}
            <feather-icon
              icon="EyeOffIcon"
              size="20"
            /> -->
            <b-form-checkbox
              v-model="routeGroup.isActive"
              class="custom-control-dark"
              name="check-button"
              :disabled="routeGroup.title === 'Impostazioni'"
              switch
              inline
              @input="checkGroup(routeGroup, index)"
            />
  <!--             @input="emitElementToSidebar({ isChecked: routeGroup.isActive, routeName: routeGroup.title })"
  -->       <feather-icon
              v-b-toggle="[`${routeGroup.title}-${index}`]"
              :icon="routeGroup.isCollapsed ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              @click="routeGroup.isCollapsed = !routeGroup.isCollapsed"
            />
          </div>
        </b-card-header>
        <b-collapse
          :id="`${routeGroup.title}-${index}`"
          :visible="false"
          accordion="my-accordion"
          role="tab"
        >
          <b-card
            v-for="(routeGroupNested, indexNested) in routeGroup.children"
            :key="`${routeGroupNested.route}-${indexNested}`"
            role="tablist"
            class="mb-0 ml-2"
            :body-class="['p-0']"
          >
            <b-card-body
              v-if="routeGroup.children"
              class="p-0"
            >
              <b-card-header
                header-tag="header"
                class="p-1"
                role="tab"
              >
                <b-row>
                  <b-col
                    class="d-flex justify-content-between"
                  >
                    <h5
                      class="ml-1 mb-0"
                      variant="info"
                    >{{ routeGroupNested.title }}</h5>
                    <feather-icon
                      v-if="!routeGroupNested.children"
                      slot="button-content"
                      icon="StarIcon"
                      size="21"
                      :class="getColorStyleBookmark(routeGroupNested)"
                      @click="setBookmarks(routeGroupNested)"
                    />
                  </b-col>
                </b-row>
                <b-form-checkbox
                  v-if="!true"
                  v-model="routeGroupNested.isBookmarked"
                  :checked="routeGroupNested.isBookmarked || false"
                  name="check-button"
                  class="mr-5"
                  inline
                />
                <div class="d-flex">

                  <b-form-checkbox
                    v-model="routeGroupNested.isActive"
                    name="check-button"
                    :disabled="routeGroupNested.title === 'Personalizza Menu'"
                    class="mr-5 custom-control-primary"
                    switch
                    inline
                    @input="checkNestedChildrenGroup(routeGroup.isActive, routeGroup, index, routeGroupNested)"
                  />
  <!--                 @input="emitElementToSidebar({ isChecked: routeGroupNested.isActive, routeName: routeGroupNested.title })"
  -->                <feather-icon
                    v-if="routeGroupNested.children"
                    v-b-toggle="[`${routeGroupNested.title}-${index}`]"
                    class="mr-2"
                    :icon="routeGroup.children.isCollapsed ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                    @click="routeGroup.children.isCollapsed = !routeGroup.children.isCollapsed"
                  />
                </div>
              </b-card-header>
              <b-collapse
                :id="`${routeGroupNested.title}-${index}`"
                visible
                accordion="my-accordion2"
                role="tab"
              >
                <b-card
                  v-for="(routeGroupNestedNested, indexNestedNested) in routeGroupNested.children"
                  :key="`${routeGroupNestedNested.route}-${indexNestedNested}`"
                  role="tablist"
                  class="mb-0 ml-3"
                  :body-class="['p-0']"
                >
                  <b-card-body
                    v-if="routeGroupNested.children"
                    class="p-0"
                  >
                    <b-card-header
                      header-tag="header"
                      class="p-1"
                      role="tab"
                    >
                      <b-row>
                        <b-col
                          class="d-flex justify-content-between"
                        >
                          <h5
                            class="ml-1 mb-0"
                            variant="info"
                          >{{ routeGroupNestedNested.title }}</h5>
                          <feather-icon
                            slot="button-content"
                            icon="StarIcon"
                            size="21"
                            :class="getColorStyleBookmark(routeGroupNestedNested)"
                            @click="routeGroupNestedNested.isBookmarked = !routeGroupNestedNested.isBookmarked;setBookmarks(routeGroupNestedNested)"
                          />
                          <b-form-checkbox
                            v-if="!true"
                            v-model="routeGroupNestedNested.isBookmarked"
                            :checked="routeGroupNestedNested.isBookmarked || false"
                            name="check-button"
                            class="mr-5"
                            inline
                          />
                        </b-col>
                      </b-row>
                      <b-form-checkbox
                        v-model="routeGroupNestedNested.isActive"
                        :checked="routeGroupNestedNested.isActive || false"
                        name="check-button"
                        class="mr-5 custom-control-info"
                        switch
                        inline
                        @input="checkLastChild(index, indexNested, routeGroupNested)"
                      />
                      <!-- @input="emitElementToSidebar({ isChecked: routeGroupNestedNested.isActive, routeName: routeGroupNestedNested.title })" -->
                    </b-card-header>
                  </b-card-body>
                </b-card>
              </b-collapse>
            </b-card-body>
          </b-card>
        </b-collapse>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import {
  BModal, BCard, BCollapse, BFormCheckbox, BCardHeader, BCardBody, VBToggle, BBadge, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'

export default {
  components: {
    BBadge,
    BModal,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
    BRow,
    BCol,

  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      visible: false,
      text: 'prova',
      allRoutes: [],
      token: `Bearer ${sessionStorage.getItem('token')}`,
      newMenu: [], // JSON.parse(sessionStorage.getItem('route')),
      // newMenu: JSON.parse(sessionStorage.getItem('routeU')),
      jsonToSend: [],
    }
  },
  computed: {
    myRoutes() {
      // return this.allRoutes
      return this.$store.getters['menuLogin/getMenu']
    },
  },
  watch: {
    // con handler() evito di usare il tasto submit
    allRoutes: {
      handler() {
        // console.log('allroutes', this.allRoutes)
        // console.log('wow', newValue)
      },
      deep: true,
    },
  },
  mounted() {
    // this.allRoutes = JSON.parse(sessionStorage.getItem('route'))
    this.allRoutes = this.myRoutes
    // console.log('mounted', this.allRoutes)
    // console.log(JSON.parse(this.allRoutes))
    // Vue.set(this.allRoutes, 'cheked', true)
    /* this.allRoutes.forEach(route => {
      if (route) Vue.set(route, 'isCollapsed', false)
      if (route.children) Vue.set(route.children, 'isCollapsed', false)
    }) */
    this.setDataForAccordion()
  },
  methods: {
    setDataForAccordion() {
      this.allRoutes.forEach(route => {
        if (route) Vue.set(route, 'isCollapsed', false)
        if (route.children) Vue.set(route.children, 'isCollapsed', false)
      })
    },
    setDataForEmit() {
      this.allRoutes.forEach(route => {
        if (route) Vue.delete(route, 'isCollapsed')
        if (route.children) Vue.delete(route.children, 'isCollapsed')
      })
    },
    handleSaveMenu(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.setDataForEmit()
      this.prepareMenuToSend()
      this.sendMenuToServer()
      // console.log('onHandleSave', this.allRoutes)
      // console.log(bvModalEvt)
      this.handleSubmit()
    },
    handleSubmit() {
      /* console.log('il mio menew da mandare', this.jsonToSend)
      console.log('this.myRoutes', this.myRoutes)
      console.log('this.allRoutes', this.allRoutes) */
      // console.log(this.newMenu)
      // Exit when the form isn't valid
      /* if (!true) {
        return
      } */
      // this.$emit('new-menu', this.allRoutes)
      // console.log('onHandleSubmit', this.allRoutes)
      // Push the name to submitted names
      // Hide the modal manually
      // console.log('handleeee', this.allRoutes)
      // this.$store.commit('menuLogin/setBookmarks', this.allRoutes)
      // const prova = [...this.allRoutes]
      this.$nextTick(() => {
        // this.$emit('new-menu', prova)
        this.$refs['modal-personalize-sidebar-menu'].toggle('#toggle-btn')
      })
      // this.$emit('new-menu', prova)
      // resetta il json da mandare
      this.jsonToSend.splice(0)
    },
    checkGroup(routeGroup, index) {
      if (!routeGroup.isActive) {
        this.allRoutes[index].children.forEach(r => {
          // eslint-disable-next-line no-param-reassign
          r.isActive = false
          return this.allRoutes
        })
      }
      if (routeGroup.isActive && !routeGroup.children.some(r => r.isActive)) {
        this.allRoutes[index].children.forEach(r => {
          // eslint-disable-next-line no-param-reassign
          r.isActive = true
          if (r.children) {
            r.children.forEach(rnested => {
              // eslint-disable-next-line no-param-reassign
              rnested.isActive = true
              return this.allRoutes
            })
          }
          return this.allRoutes
        })
      }
      return this.allRoutes
    },
    checkNestedChildrenGroup(routeGroupActive, routeGroup, index, routeGroupNested) {
      if (!routeGroupNested.isActive && routeGroupNested.children) {
        routeGroupNested.children.forEach(r => {
          // eslint-disable-next-line no-param-reassign
          r.isActive = false
          return this.allRoutes
        })
      }
      if (routeGroupNested.isActive && routeGroupNested.children && !routeGroupNested.children.some(r => r.isActive)) {
        routeGroupNested.children.forEach(r => {
          // eslint-disable-next-line no-param-reassign
          r.isActive = true
        })
      }
      if (!routeGroup.children.some(r => r.isActive)) {
        this.allRoutes[index].isActive = false
      }
      if (!routeGroupActive && routeGroup.children.some(r => r.isActive)) {
        this.allRoutes[index].isActive = true
      }
    },
    checkLastChild(index, indexNested, father) {
      if (!father.children.some(r => r.isActive)) {
        this.allRoutes[index].children[indexNested].isActive = false
      } else {
        this.allRoutes[index].children[indexNested].isActive = true
      }
    },
    countBookmarks(routeGroup) {
      let count = 0
      routeGroup.children.forEach(children => {
        if (!Object.prototype.hasOwnProperty.call(children, 'children')) {
          if (children.isBookmarked) {
            count += 1
          }
        }
        if (Object.prototype.hasOwnProperty.call(children, 'children')) {
          children.children.forEach(nestedChildren => {
            if (nestedChildren.isBookmarked) count += 1
          })
        }
      })
      // const routeFirstChildren = route.children.filter(r => r.isActive)
      /* routeGroup.children.forEach(r => {
        // console.log('r', r.title)
        if (Object.prototype.hasOwnProperty.call(r, 'children')) {
          r.children.filter(r1 => {
            // console.log('r1', r1.title)
            if (r1.isBookmarked === true || !Object.prototype.hasOwnProperty.call(r, 'children')) {
              count += 1
            }
            return count
          })
        }
        return count
      }) */
      return count
    },
    countVisible(route) {
      let count = 0
      route.children.filter(r => {
        // console.log(r)
        if (r.isActive) {
          count += 1
        }
        return count
      })
      // const routeFirstChildren = route.children.filter(r => r.isActive)
      route.children.forEach(r => {
        // console.log('r', r.title)
        if (Object.prototype.hasOwnProperty.call(r, 'children')) {
          r.children.filter(r1 => {
            // console.log('r1', r1.title)
            if (r1.isActive === true || !Object.prototype.hasOwnProperty.call(r, 'children')) {
              count += 1
            }
            return count
          })
        }
        return count
      })
      return count
    },
    countNotVisible(route) {
      let count = 0
      route.children.filter(r => {
        // console.log(r)
        if (!r.isActive) {
          count += 1
        }
        return count
      })
      // const routeFirstChildren = route.children.filter(r => r.isActive)
      route.children.forEach(r => {
        // console.log('r', r.title)
        if (Object.prototype.hasOwnProperty.call(r, 'children')) {
          r.children.filter(r1 => {
            // console.log('r1', r1.title)
            if (r1.isActive !== true) {
              count += 1
            }
            return count
          })
        }
        return count
      })
      return count
    },
    getColorStyleBookmark(route) {
      return route.isBookmarked ? 'text-warning fill-star' : 'text-warning'
    },
    setBookmarks(route) {
      // console.log('routesss', this.myRoutes)
      this.$store.commit('menuLogin/pushBookmarks', route)
      // console.log('setRouteeeeee0', route)
    },
    async sendMenuToServer() {
      // console.log('dentro send menu')
      /* this.prepareMenuToSend()
      console.log(this.newMenu) */
      /* console.log({
        menu: this.jsonToSend,
        // description: this.description,
        // token: this.token,
      })
      console.log('organization', String(JSON.parse(sessionStorage.getItem('activeAgency')).value)) */
      const data = JSON.stringify(this.jsonToSend)
      // console.log(data)
      await axios.post(process.env.VUE_APP_URL_API_SERVER,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: String(JSON.parse(sessionStorage.getItem('activeAgency')).value),
            classe: 'Utente',
            metodo: 'setMenu',
            ACL: 'button',
          },
        }).then(res => res)
        .catch(e => e)
      // this.newMenu.splice(0)
    },
    prepareMenuToSend() {
      this.newMenu = [...this.myRoutes]
      this.newMenu.forEach(element => {
        // this.jsonToSend
        this.jsonToSend.push({
          key: element.key,
          isActive: element.isActive ? '1' : '0',
          isBookmarked: '0',
        })
        if (Object.prototype.hasOwnProperty.call(element, 'children')) {
          element.children.forEach(el => {
            this.jsonToSend.push({
              key: el.key,
              isActive: el.isActive ? '1' : '0',
              isBookmarked: el.isBookmarked ? '1' : '0',
            })
            if (Object.prototype.hasOwnProperty.call(el, 'children')) {
              el.children.forEach(child => {
                this.jsonToSend.push({
                  key: child.key,
                  isActive: child.isActive ? '1' : '0',
                  isBookmarked: child.isBookmarked ? '1' : '0',
                })
              })
            }
          })
        }
      })
    },
    koAlert(message) {
      this.$swal({
        title: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    successAlert(message) {
      this.$swal({
        title: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
<style scoped>
.fill-star {
  fill: #ff9f43;
}
</style>
