<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col :bookmarksss="mybookmarks" -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <Bookmarks
        :bookmarksss-to-seach="mybookmarksToSerch"
      />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale />
      <dark-Toggler class="d-none d-lg-block" />
      <SearchBar />
      <NotificationDropdown /> -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              <!-- {{ email.split('@')[0] }} -->
              {{ emailComputed }}
            </p>
            <!-- <span class="user-status">Admin</span> -->
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :text="emailComputedToUpperCase"
            class="badge-minimal"
            badge-variant="success"
          />
          <!-- :src="require('@/assets/images/avatars/13-small.png')" -->
        </template>

        <!-- <b-dropdown-item
          :to="{ name: 'feed-profile'}"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item> -->
        <b-dropdown-item
          :to="{ name: 'personal-profile'}"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profilo</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item
          :to="{ name: 'apps-email' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item
          :to="{ name: 'apps-todo' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item
          :to="{ name: 'apps-chat' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item> -->
        <!-- APRE MODAL EDIT-PASSWORD CONTROLLA REF-->
        <!-- <b-dropdown-item
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-edit-from-menu-logout
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserCheckIcon"
            class="mr-50"
          />
          <span>Password</span>
        </b-dropdown-item>
        <ChangePassword :id="'modal-edit-from-menu-logout'" /> -->
        <!-- <b-dropdown-item
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-register-user-from-dropdown-navbar
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserPlusIcon"
            class="mr-50"
          />
          <span>Account</span>
        </b-dropdown-item>
        <RegisterUser :id="'modal-register-user-from-dropdown-navbar'" /> -->
        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, VBModal,
} from 'bootstrap-vue'

// import { initialAbility } from '@/libs/acl/config'
import Bookmarks from '@core/layouts/components/app-navbar/components/Bookmarks.vue'
/* import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue' */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
/* import store from '@/store'
import axios from 'axios' */
// import ChangePassword from '@/views/pages/modal/ChangePassword.vue'
// import RegisterUser from '@/views/pages/modal/RegisterUser.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // ChangePassword,
    // RegisterUser,
    Bookmarks,

    // Navbar Components
    /* DarkToggler,
    SearchBar,
    NotificationDropdown,
    Locale, */
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
    /* bookmarkss: {
      type: Array,
      required: true,
    }, */
    bookmarkssToSearch: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      email: sessionStorage.getItem('emailVet'),
      // bookmarks: this.bookmarkss,
    }
  },
  computed: {
    /* mybookmarks() {
      return this.bookmarkss
    }, */
    mybookmarksToSerch() {
      // console.log('dentro computed navbar', this.bookmarkssToSearch)
      return this.bookmarkssToSearch
    },
    emailComputed() {
      // console.log(this.email)
      if (this.email) {
        return this.email.split('@')[0]
      }
      return ''
    },
    emailComputedToUpperCase() {
      if (this.email) {
        return this.email.split('@')[0][0].toUpperCase()
      }
      return ''
    },
  },
  watch: {},
  created() {},
  beforeMount() {
    // this.name = store.getters['login/getName']
    // console.log('sono su created navabr', this.bookmarkss)
    // this.bookmarks = JSON.parse(sessionStorage.getItem('bookmarks'))
  },
  beforeDestroy() {
    // this.logout()
  },
  methods: {
    async logout() {
      sessionStorage.clear()
      localStorage.clear()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sessione terminata correttamente',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
      this.$router.push('/login')
      // localStorage.removeItem('token')
      // localStorage.removeItem('emailVet')
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      // localStorage.removeItem('userData')
      /* if (sessionStorage.getItem('token') == null) {
        localStorage.removeItem('emailVet')
        this.$router.push('/login')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sessione terminata correttamente',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } else {
        const token = `Bearer ${sessionStorage.getItem('token')}`
        // console.log(token)
        await axios.post('https://manager.petergoodstrong.com/api/logout', {}, {
          headers: {
            Authorization: token,
          },
        })
          .then(res => {
            // console.log(res)
            sessionStorage.removeItem('token')
            localStorage.removeItem('emailVet')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.messaggio,
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'login' })
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sessione terminata correttamente',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.push('/login')
          })
      } */
    },
  },
}
</script>
