<template>
  <ul class="mb-5">
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref, onBeforeMount } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  // emits: ['from-vertical-nav-menu-items'],
  setup() {
    onBeforeMount(() => {
      // console.log('menu items', props)
      // console.log(props.items)
    })
    provide('openGroups', ref([]))

    /* const emitToVerticalNavMenuL = newMenu => {
      emit('from-vertical-nav-menu-items', newMenu)
      // console.log('from-vertical-nav-menu-items', newMenu)
    } */
    return {
      // emitToVerticalNavMenuL,
      resolveNavItemComponent,
    }
  },
}
</script>
